import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";

// Custom hook to block navigation
const useBlockNavigation = (
  shouldBlock,
  showModalCallback,
  isPaymentInitiated,
  isModalClosed
) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (shouldBlock && !isPaymentInitiated) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    const handleNavigation = (event) => {
      const clickedElement = event.target.closest(
        "a, button, [data-link], #discount-code-input"
      );

      const isPaytrailLink =
        clickedElement &&
        clickedElement.href &&
        clickedElement.href.includes("paytrail");

      if (
        shouldBlock &&
        !isPaymentInitiated &&
        !isPaytrailLink &&
        !isModalClosed &&
        clickedElement.id !== "discount-code-input" &&
        clickedElement.id !== "apply-discount-button"
      ) {
        event.preventDefault();
        showModalCallback();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handleNavigation);
    window.addEventListener("click", handleNavigation);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleNavigation);
      window.removeEventListener("click", handleNavigation);
    };
  }, [shouldBlock, showModalCallback, isPaymentInitiated, isModalClosed]);
};

const Welcome = () => {
  const { user, loading } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [membershipDuration, setMembershipDuration] = useState(12);
  const [discountCode, setDiscountCode] = useState("");
  const [discountApplied, setDiscountApplied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isPaymentInitiated, setIsPaymentInitiated] = useState(false);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const membershipOptions = {
    12: { price: 3990, productCode: "1a" },
    36: { price: discountApplied ? 3990 : 9990, productCode: "1b" },
    60: { price: 14990, productCode: "1c" },
  };

  useBlockNavigation(
    user?.membership?.active === 0 && !isPaymentInitiated,
    () => setShowModal(true),
    isPaymentInitiated,
    isModalClosed
  );

  useEffect(() => {
    if (!loading) {
      // Redirect to login if the user is not logged in
      if (!user) {
        navigate("/kirjaudu"); // Replace with your actual login route
      } else if (user.membership?.active === 1) {
        navigate("/"); // Redirect to home if membership is active
      }
    }
  }, [user, loading, navigate]);

  useEffect(() => {
    if (user && user.membership?.active === 1) {
      navigate("/");
    }
  }, [user, navigate]);

  const initiatePayment = async () => {
    try {
      setIsPaymentInitiated(true);

      const selectedOption = membershipOptions[membershipDuration];
      const paymentData = {
        email: user.email,
        amount: selectedOption.price,
        userId: user.id,
        order: [
          {
            unitPrice: selectedOption.price,
            units: 1,
            productCode: selectedOption.productCode,
          },
        ],
      };

      const response = await axios.post(
        `${apiUrl}/payments/create-payment`,
        paymentData
      );
      const result = response.data;

      if (result.href) {
        window.location.href = result.href;
      } else {
        console.log("Payment creation failed:", result);
        setIsPaymentInitiated(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setIsPaymentInitiated(false);
    }
  };

  const applyDiscountCode = () => {
    if (discountCode === "vaihtolomat3v") {
      setDiscountApplied(true);
      setMembershipDuration(36); // Automatically select the 36-month option
      setModalMessage(
        "Alennuskoodi hyväksytty! 36 kuukauden jäsenyys on nyt alennettu hintaan 39,90€."
      );
    } else if (discountCode === "vaihtolomat1v") {
      // Skip payment and activate 1-year membership directly
      activateFreeMembership();
      return; // Skip setting the modal here; handled in activateFreeMembership
    } else {
      setDiscountApplied(false);
      setModalMessage("Väärä alennuskoodi.");
      setShowModal(true);
    }
  };

  const activateFreeMembership = async () => {
    try {
      const response = await axios.post(`${apiUrl}/auth/activate-free`, {
        userId: user.id,
      });
      if (response.status === 200) {
        setModalMessage(
          "Jäsenyys aktivoitu! Voit nyt käyttää palvelua ilmaiseksi vuoden ajan."
        );
        navigate("/"); // Redirect to homepage or another page
      } else {
        setModalMessage("Jäsenyyden aktivointi epäonnistui. Yritä uudelleen.");
      }
    } catch (error) {
      console.error("Error activating free membership:", error);
      setModalMessage("Jäsenyyden aktivointi epäonnistui. Yritä uudelleen.");
    }
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setIsModalClosed(true);
    setTimeout(() => setIsModalClosed(false), 500);
  };

  if (user?.membership?.active === 1) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg shadow-lg w-full max-w-xl w-4/5 sm:max-w-2xl mx-auto mt-12 sm:mt-28 p-4 sm:p-6 flex flex-col">
      {showModal && !isPaymentInitiated && (
        <Modal isVisible={showModal} onClose={handleModalClose}>
          <p className="text-base sm:text-lg m-4 text-center">{modalMessage}</p>
        </Modal>
      )}

      <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold text-center m-4">
        Tervetuloa Vaihtolomat.fi-palveluun, {user?.name}!
      </h1>
      <p className="text-base sm:text-lg m-4 text-center">
        Olette nyt luoneet käyttäjän ja lisänneet omistamanne lomaviikon
        tilillenne. <br /> Vielä pari askelta, ennen kuin pääsette varaamaan
        vapaita lomaviikkoja:
      </p>

      <ul className="list-disc text-base sm:text-lg m-4 space-y-4">
        <li>Valitse jäsenyys:</li>
        <p>
          1 vuoden jäsenyys,{" "}
          <span class="text-red-500 line-through">39,90€</span> 0€ alekoodilla{" "}
          <span class="text-teal-600 font-bold">vaihtolomat1v</span>, kun
          talletat viikkosi 31.12.2024 mennessä! (sis. 20 krediittiä)
        </p>

        <p>3 vuoden jäsenyys, 99,90€, (sis. 50 krediittiä)</p>
        <p>5 vuoden jäsenyys, 149,90€ (sis. 75 krediittiä)</p>
        <li>Talleta omistamasi lomaviikko (Oma sivu)</li>
        <li>
          Mitä aiemmin talletat viikkosi, sitä enemmän ansaitset krediittejä!
        </li>
      </ul>

      <div className="text-center mb-4">
        <input
          type="text"
          placeholder="Alennuskoodi"
          className="border border-gray-300 rounded-lg px-4 py-2 mr-4"
          value={discountCode}
          onChange={(e) => setDiscountCode(e.target.value)}
          id="discount-code-input"
        />

        <button
          onClick={applyDiscountCode}
          className="px-4 py-2 bg-teal-600 text-white rounded-lg"
          id="apply-discount-button"
        >
          Lisää koodi
        </button>
      </div>

      <div className="mb-6 text-center">
        <label className="block mb-2 text-lg font-semibold">
          Valitse jäsenyys:
        </label>
        <select
          value={membershipDuration}
          onChange={(e) => setMembershipDuration(Number(e.target.value))}
          className="border border-2 border-teal-600 focus:border-teal-700 rounded-lg px-4 py-2 text-lg"
        >
          <option value={12}>12 kuukautta - 39,90€</option>

          <option value={36}>
            36 kuukautta - {discountApplied ? "39,90€ (alennettu)" : "99,90€"}
          </option>
          <option value={60}>60 kuukautta - 149,90€</option>
        </select>
      </div>

      <button
        className="w-full sm:w-3/4 lg:w-1/2 mx-auto my-8 justify-center rounded-3xl bg-teal-600 px-4 sm:px-5 py-2 sm:py-3 text-base sm:text-lg lg:text-xl font-roboto leading-6 text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange"
        onClick={initiatePayment}
      >
        Maksamaan
      </button>
    </div>
  );
};

export default Welcome;
